<template>
  <div class="result-container">
    <div class="row1">
      <div style="display:flex;justify-content: space-between;">
      <div class="row1-header">
        <span style="margin-left: 10px; margin-right: 20px">{{
          orderInfo.buyer_login_id
        }}</span>
        <span v-if="orderInfo.express_print_state=='1'" style="color:#488b00;margin-right:5px">✔</span>
        <span v-if="orderInfo.invoice_print_state=='1'" style="color:#3da6e3;margin-right:5px">✔</span>
        <el-button
          size="mini"
          v-clipboard:copy="orderInfo.buyer_login_id"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制名字</el-button
        >
      </div>
      <div v-if="orderInfo.logistics_bill_no" style="padding-right:15px;">快递单号：{{orderInfo.logistics_bill_no}}</div>
      </div>
      <el-divider></el-divider>
      <div class="row1-body">
        <table border="0" class="body-infotable" cellspacing="5">
          <tr >
            <td class="table-key"><span style="text-justify:distribute-all-lines;
          text-align-last: justify;
          text-align: justify;
          display: inline-block;
          width: 50px;">收件人</span>:</td>
            <td class="table-value">
              ***
              <!-- {{ showAll ? decryptInfo.contactPerson : orderInfo.to_full_name }} -->
            </td>
          </tr>
          <tr>
            <td class="table-key"><span style="text-justify:distribute-all-lines;
          text-align-last: justify;
          text-align: justify;
          display: inline-block;
          width: 50px;">电话</span>:</td>
            <td class="table-value">
              <!-- {{  showAll ? decryptInfo.mobile : orderInfo.to_mobile }} -->
              ***********
              </td>
          </tr>
          <!-- <tr>
            <td class="table-key">邮编:</td>
            <td class="table-value">{{ orderInfo.to_division_code }}</td>
          </tr> -->
          <tr>
            <td class="table-key">收件地址:</td>
            <td class="table-value" v-if="addressEdit == false">
              <!-- <span>{{
                orderInfo.province +
                " " +
                orderInfo.city +
                " " +
                orderInfo.area +
                " " +
                orderInfo.town +
                " " +
                (showAll ? decryptInfo.address : orderInfo.address)
              }}</span> -->
              <span>{{
                orderInfo.province +
                " " +
                orderInfo.city +
                " " +
                orderInfo.area +
                " " 
              }}**</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row3">
    <div class="row3-header"  v-for="(item, index) in orderInfo.order_sub" :key="index">
      <div class="shopMsgRow-left">
        <div class='shopMsgRow'>
          <span style="margin-right: 5px;">供货商单号:</span>
          <span class="header-item">{{ item.order_no }}</span>
        </div>
        <div class='shopMsgRow'>
          <span style="margin-right: 5px">小店订单号:</span>
          <span class="header-item">{{ item.shop_order_id }}</span>
        </div>
        <div class='shopMsgRow'>
          <span style="margin-right: 5px;
          text-justify:distribute-all-lines;
          text-align-last: justify;
          text-align: justify;
          display: inline-block;
          width: 68px;">商品规格:</span>
          <span class="header-item" style="color:#666;" v-for="(sku_item,sku_index) in item.sku_info" :key="sku_index">
          {{sku_item.name}}({{sku_item.value}})
          </span>
        </div>
        <div class='shopMsgRow'>
          <span style="margin-right: 5px; text-justify:distribute-all-lines;
          text-align-last: justify;
          text-align: justify;
          display: inline-block;
          width: 68px;">商品数量:</span>
          <span class="header-item" style="color: #DA1717;font-weight:600;">
          {{item.quantity}}
          </span>
        </div>
      </div>
      <div class="shopMsgRow-right">
        <!-- <div class='shopMsgRow' style="color: #DA1717;font-weight: 600;">{{orderInfo.order_status_desc | orderStatusFormat}}</div> -->
        <div class='shopMsgRow' style="color: #DA1717;font-weight: 600;">{{item.order_status_desc }}</div>
        <div class='shopMsgRow' style="color:#666;">{{item.create_time }}</div>
        <div class='shopMsgRow'>订单金额:{{item.item_amount}}元</div>
        <div class='shopMsgRow'>实付:{{item.item_amount}}元</div>
        </div>
      </div>
    </div>
   
    <div class="row5">
      <!-- <div class="row5-col1">
        <div class="col1-header">
          <span>要在打印单上打印的发货内容:</span>
          <div>
                <span style="margin-right:10px">设置:</span>
                <el-button type="text" style="font-size: 12px; margin-right: 20px;">打印内容</el-button>
                <el-button type="text" style="font-size: 12px;">过滤词</el-button>
            </div>
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          placeholder="请输入内容"
          v-model="orderInfo.invoice_content"
        >
        </el-input>
      </div> -->
      
      <div class="row5-col2">
        <el-button
          class="col2-btn btn1"
          @click="printDeliverGoodsBtn"
          >打印发货单</el-button
        >
        <el-button
          class="col2-btn btn2"
          @click="printExpressBtn"
          >打印快递单</el-button
        >
      </div>
      <el-button @click="handleClickDeliverGoods" size="mini" class="row5-btn">发货</el-button>
    </div>
    <div class="row6">
      <!-- <ul>
        <li>
          <span style="color: #ff2f2f">温馨提示:</span>
        </li>
        <li>
          <span>*以上内容会打印到快递单上，如果不想打印请</span>
          <el-button
            type="text"
            style="font-size: 12px; color: #ff2f2f"
            @click="deleteTextarea"
            >删除</el-button
          >
        </li>
        <li>
            <span>*建议使用<span style="color: #7DDE70">编辑打印简称功能</span>，可以提高</span>
            <span style="color: #7DDE70">配货、发货效率</span>
            </li>
        <li>
          <span
            >*点击<span style="color: #7dde70">发货</span
            >会提示输入快递单号，只需输入一次，以后自动增加</span
          >
        </li>
      </ul> -->
    </div>
    <!-- 发货单设置发件人弹窗 -->
    <my-dialog-3
      :centerDialogVisible="dialogFahuoConsignerVisible"
      :popupWidth="'500px'"
      @updateVisible="updateFahuoConsignerVisible"
      @openDialog="openFahuoConsignerDialog"
    >
      <template slot="content">
        <div class="consigner-dialog">
          <span style="margin-bottom: 30px">请选择发货单发件人</span>
          <el-radio-group v-model="activeFahuoConsignerIndex">
            <el-radio
              v-for="(item, index) in fahuoConsignerList"
              :key="index"
              :label="index + ''"
              >{{ item.title }}</el-radio
            >
          </el-radio-group>
          <span v-show="fahuoConsignerList.length == 0"
            >暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()"
              >这里</el-button
            >进行设置</span
          >
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          @click="handleChooseFahuoConsigner"
          size="mini"
          >确定</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogFahuoConsignerVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    
    <!-- 打印发货单弹窗 -->
    <my-dialog-3
      :centerDialogVisible="dialogFahuoPrintVisible"
      :popupWidth="'500px'"
      @updateVisible="updateFahuoPrintVisible"
    >
      <template slot="content">
        <div class="fahuo-print-dialog">
          <el-form
            label-position="right"
            label-width="120px"
            :model="fahuoPrintOption"
          >
            <el-form-item label="选择打印机：">
              <el-select
                v-model="fahuoPrintOption.printer"
                placeholder="选择打印机"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in printerList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select
                v-model="fahuoPrintOption.num"
                placeholder="选择份数"
                size="mini"
              >
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          size="mini"
          @click="doPrintFahuo"
          >打印发货单</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogFahuoPrintVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    <!-- 快递单设置发件人弹窗 -->
    <my-dialog-3
      :centerDialogVisible="dialogExpressConsignerVisible"
      :popupWidth="'500px'"
      @updateVisible="updateExpressConsignerVisible"
      @openDialog="openExpressConsignerDialog"
    >
      <template slot="content">
        <div class="consigner-dialog">
          <span style="margin-bottom: 30px">请选择快递单发件人</span>
          <el-radio-group v-model="activeExpressConsignerIndex">
            <el-radio
              v-for="(item, index) in expressConsignerList"
              :key="index"
              :label="index + ''"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <span v-show="expressConsignerList.length == 0"
            >暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()"
              >这里</el-button
            >进行设置</span
          >
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          @click="handleChooseExpressConsigner"
          size="mini"
          >确定</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogExpressConsignerVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    <my-dialog-3
      :centerDialogVisible="dialogExpressPrintVisible"
      :popupWidth="'500px'"
      @updateVisible="updateExpressVisible"
    >
      <template slot="content">
        <div class="express-print-dialog">
          <el-form
            label-position="right"
            label-width="120px"
            :model="expressPrintOption"
          >
            <!-- <el-form-item label="快递模板：">
            <el-button type="text" style="margin-right:20px;">{{expressTemplate | expressFormat}}</el-button>
          </el-form-item> -->
            <el-form-item label="选择打印机：">
              <el-select
                v-model="expressPrintOption.printer"
                placeholder="选择打印机"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in printerList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select
                v-model="expressPrintOption.num"
                placeholder="选择份数"
                size="mini"
              >
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <!-- <el-button type="text" style="margin-right:20px;">预览</el-button> -->
        <el-button
          class="footer-btn btn1"
          type="primary"
          size="mini"
          @click="DetectPrintedExpress"
          >打印快递单</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogExpressPrintVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3>
    
    <el-dialog
        :visible.sync="dialogShow"
        width="23%"
        background-color='#fff'
        center>
        <div class="contentClass">
          <i class="el-icon-success"></i>
          <span>成功复制到剪切板！</span>
        </div>
    </el-dialog>
    
    <el-dialog
        :visible.sync="dialog2Show"
        width="23%"
        background-color='#fff'
        :show-close = 'false'
        center>
        <div class="contentClass contentClass2">
          <div>
            <i class="el-icon-warning"></i>
            <span>请勾选打印的商品</span>
          </div>
          <div class="contentFooter">
            <p @click="dialog2Show=false">确定</p>
          </div>
        </div>
    </el-dialog>
    
    <!-- 已打印弹窗 -->
    <reprintDialog1 v-if="dialogShow10" @ok="()=>{this.dialogFahuoConsignerVisible=true;this.dialogShow10=false}" @cancel="()=>{this.dialogShow10=false}" />
    <reprintDialog2 v-if="dialogShow5" @use_new="doPrintExpress(0)" @use_old="doPrintExpress(1)" @cancel="()=>{this.dialogShow5=false}" />
    
    <GetExpressErrorPopup v-if="GetExpressError" :dataSrc="GetExpressError" @closePopup="()=>{this.GetExpressError=false}"></GetExpressErrorPopup>
    <GetFahuoErrorPopup v-if="GetFahuoError" :dataSrc="GetFahuoError" @closePopup="()=>{this.GetFahuoError=false}"></GetFahuoErrorPopup>

    <appDialog @closePopup="()=>{this.print_component_error=false}"  v-if="print_component_error" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { lodopPreview, lodopSinglePrint } from "@/utils/functions.js";
import { getPrinterList } from "@/utils/lodopPrint";
import provinceCity from "@/static/locationOption.json";
import myDialog2 from "@/UI/myDialog2";
import myDialog3 from "@/UI/myDialog3";
import myDialog4 from "@/UI/myDialog4";
import reprintDialog1 from "@/components/dialog/reprintDialog1";
import reprintDialog2 from "@/components/dialog/reprintDialog2";
import GetExpressErrorPopup from "@/UI/GetExpressErrorPopup";
import GetFahuoErrorPopup from "@/UI/GetFahuoErrorPopup";
import appDialog from "@/components/appDialog";


export default {
  name: "ResultInfo",
  components: {
    myDialog2,
    myDialog3,
    myDialog4,
    reprintDialog1,
    reprintDialog2,
    GetExpressErrorPopup,
    GetFahuoErrorPopup,
    appDialog,
  },
  created() {
    this.provinces = provinceCity[0];
  },
  data() {
    return {
      // 打印机列表
      printerList: this.PRINTERLIST || window.douyin_print_list,
      activeFahuoIndex: "0",
      // 默认的渲染窗口
      activeExpressIndex: "0",
      // 底部textarea绑定数据
      textarea: "",
      // 地址输入框控制参数
      addressEdit: false,
      // 发货单发货人设置弹窗变量
      dialogFahuoConsignerVisible: false,
      activeFahuoConsignerIndex: "0",
      fahuoConsigner: {},
      dialogFahuoPrintVisible: false,
      fahuoPrintOption: {
        printer: "",
        num: "1",
      },
      // 快递单发货人设置弹窗变量
      dialogExpressConsignerVisible: false,
      activeExpressConsignerIndex: "0",
      expressConsigner: {},
      dialogExpressPrintVisible: false,
      expressPrintOption: {
        printer: "",
        num: "1",
      },
      // 发货单弹窗变量
      dialogFahuoVisible: false,
      // 快递单弹窗变量
      dialogExpressVisible: false,
      // 添加快递模板弹窗变量
      dialogAddExpressTemplateVisible: false,
      dialogExpressGlobalSettingsVisible: false,
      expressTemplateListTemp: null,
      SelectedExpressTemplateModifySequenceLabel:0,
      ExpressGlobalSettingsFontFamily:"宋体",
      ExpressGlobalSettingsFontSize:"14",
      ExpressGlobalSettingsFontWeight:"加粗",
      ExpressGlobalSettingsScaleZoom_Transverse: 50, // 快递单横向缩放
      ExpressGlobalSettingsScaleZoom_Longitudinal: 50, // 快递单纵向缩放

      showAll:false,
      flagShow: false,
      dialog2Show: false,     
    
    
      dialogShow: false,
      dialogShow5: false, // 判断是否有打印过的订单
      dialogShow10: false, // 原单号打印的弹窗
      
      GetExpressError: false, // 快递打印获取单号错误
      GetFahuoError: false, // 发货错误

      print_component_error: false,
      
   
    };
  },
  computed: {
    ...mapState({
      orderId: (state) => state.singleprint.orderId,
      resultList: (state) => state.singleprint.resultList,
      circularType: (state) => state.singleprint.circularType,
      orderInfo: (state) =>state.singleprint.orderInfo,
      decryptInfo:(state)=>state.singleprint.decryptInfo,
      expressTemplateList: (state) => state.print.expressTemplateList,
      expressList: (state) => state.print.expressList,
      fahuoConsignerList: (state) => state.print.fahuoConsignerList,
      expressConsignerList: (state) => state.print.expressConsignerList,
      // 打印参数
      printOrderInfo: (state) => state.singleprint.printOrderInfo,
      printExpressTemplate: (state) => state.singleprint.printExpressTemplate,
      printExpressBgimg: (state) => state.singleprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.singleprint.printFahuoBgimg,
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
      WaybillSite: (state)=> state.batchprint.WaybillSite,
      get_shop_info: (state)=> state.setting.get_shop_info.data,
      expressTemplate: (state)=> state.setting.expressTemplate,
    }),
    addExpressId: {
      get() {
        return this.$store.state.print.addExpressId;
      },
      set(val) {
        this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
      },
    },
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
      }
    }
  },
  methods: {
    // 跳转设置
    jumptoSetting() {
      this.$router.push("/setting");
    },
    // 复制成功时的回调函数
    onCopy(e) {
      // this.$message.success("内容已复制到剪切板！");
      this.dialogShow = true
      setTimeout(()=> {
        this.dialogShow = false
      }, 3000)
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("复制失败！");
    },
    // 清空textarea
    deleteTextarea() {
      this.orderInfo.invoice_content = "";
    },
   
    
  
   
  
    showInfo(showAll){
      this.showAll=!showAll;
    },
    showExpressPrintDialog() {
      this.dialogExpressPrintVisible = true;
    },
    showFahuoPrintDialog() {
      this.dialogFahuoPrintVisible = true;
    },
    updateExpressVisible(val){
      this.dialogExpressPrintVisible = val
    },
    // 判断是否存在打印过订单
    DetectPrintedExpress(){
      this.dialogExpressPrintVisible = false
      if(!this.expressPrintOption.num || !this.expressPrintOption.printer ){
        this.$message.error("请选择打印机和打印份数");
        return false;
      }else if(this.orderInfo.express_print_state==1){
          this.dialogShow5 = true
      }else {
        this.doPrintExpress(0)
      }
    },
    // 打印函数
    doPrintExpress( is_old ) {
      let params = {
        is_old: is_old==1?is_old:0, // 0为新单号打印 1为原单号
        create_time: [this.orderInfo.create_time],
        transaction_id: [this.orderInfo.transaction_id],
        address_express_id: this.expressConsignerList[this.activeExpressConsignerIndex].id,
        template_id: this.expressTemplateList[this.expressTemplate]["id"],
      }

      this.$store.dispatch("print/GetExpressOrderInfo", params).then(res=>{
        if( res.code == 0){
          this.dialogShow5 = false
          if(res.message != "成功"){
            this.GetExpressError = res.message
          }
          if(res.data.length>0){
            // params 参数： printer 打印机, waybillArray 订单数组 , preview = false //true预览 false打印
            let print_params = {
              printer: this.expressPrintOption.printer,
              preview: false,
              waybillArray:  res.data
            }
            let TaskID = window.douyin_print.doPrint(print_params)
          }
          this.$store.dispatch("singleprint/get_search_result")
        }else{
          this.$message.error(res.message);
        }
      })
    },
    updateFahuoPrintVisible(val){
      this.dialogFahuoPrintVisible=val
    },
    
    // 发货
    handleClickDeliverGoods() {
      this.$store.dispatch("batchprint/OpDeliverySendOrder",{data:[this.orderInfo]}).then(res=>{
        if(res.code!=0){
          this.$message.error(res.message)
        }else{
          if(res.message != "成功"){
            this.GetFahuoError = res.message
          }else{
            this.$message.success("发货成功")
          }
          this.$store.dispatch("singleprint/get_search_result")
        }
      })
    },
    doPrintFahuo() {
      if(!this.fahuoPrintOption.num || !this.fahuoPrintOption.printer ){
        this.$message.error("请选择打印机和打印份数");
        return false
      }
      this.dialogFahuoPrintVisible = false
      lodopSinglePrint(
        this.LODOP,
        "2",
        this.fahuoPrintOption.printer,
        this.fahuoPrintOption.num,
        this.orderInfo,
        this.fahuoConsigner,
        this.printFahuoBgimg,
        this.printFahuoTemplate
      ).then(() => {
        this.$store
          .dispatch("singleprint/update_fahuo_state", {
            transaction_id: this.orderInfo.transaction_id + "|" + this.orderInfo.create_time,
          })
          .then((res) => {
            this.$store.dispatch("singleprint/get_search_result")
          });
      });
    },
    // 发货单发件人设置弹窗函数
    updateFahuoConsignerVisible(val) {
      this.dialogFahuoConsignerVisible = val;
    },
    openFahuoConsignerDialog() {
      this.$store.dispatch("print/get_fahuo_consigner_list");
    },
    handleChooseFahuoConsigner() {
      this.dialogFahuoConsignerVisible = false
      let fahuoConsigner = this.fahuoConsignerList[
        this.activeFahuoConsignerIndex
      ];
      this.fahuoConsigner = fahuoConsigner;
      this.dialogFahuoPrintVisible = true;
    },
    // 快递单发件人设置弹窗函数
    updateExpressConsignerVisible(val) {
      this.dialogExpressConsignerVisible = val;
    },    
    openExpressConsignerDialog() {
      this.$store.dispatch("print/get_express_consigner_list");
    },
    handleChooseExpressConsigner() {
      this.dialogExpressConsignerVisible = false
      let expressConsigner = this.expressConsignerList[
        this.activeExpressConsignerIndex
      ];
      this.expressConsigner = expressConsigner;
      this.dialogExpressPrintVisible = true;
    },
    // =======================
    circularTypeChange(num) {
      let resultList = JSON.parse(JSON.stringify(this.resultList))
      let arr = []
      for(let i in resultList.data) {
        arr.push(resultList.data[i].transaction_id)
      }
      if(arr.indexOf(this.orderId)!==-1) {
        resultList.data[arr.indexOf(this.orderId)].circularType = num
      }
      this.$store.commit("singleprint/SET_RESULT_LIST", resultList);
      let orderInfo = this.orderInfo
      orderInfo.circularType = num
      this.$store.commit("singleprint/SET_ORDERINFO", orderInfo);
      let params = {
        orderId: orderInfo.transaction_id,
        create_time: orderInfo.create_time,
        sign: num
      }
      this.$store
      .dispatch("singleprint/updateSign", params)
      .then((res) => {
			this.$message.error(res.message);
		});
    },
    editNameBtn(item,index) {
      // this.editName = !this.editName
      this.orderInfo.order_sub[index].inputType = true
      if(item.sku_short_name != '') {
        this.orderInfo.order_sub[index].inputValue  = item.sku_short_name
      }else {
        if(item.baby_short_name != '') {
          this.orderInfo.order_sub[index].inputValue  = item.baby_short_name
        }else {
          this.orderInfo.order_sub[index].inputValue  = item.name
        }
      }
    },
    noneEditNameBtn(item, index) {
      this.orderInfo.order_sub[index].inputType = false
    },
    confirmEditNameBtn(item, index) {
    },
    async printDeliverGoodsBtn() {
      try {
        let lodop_printer = await getPrinterList();
        if(this.orderInfo.invoice_print_state=="1" ){
          this.dialogShow10 = true
        }else{
          this.dialogFahuoConsignerVisible = true
        }
      } catch (error) {
        this.print_component_error = true
      }
    },
    printExpressBtn() {
      if(window.douyin_print_error || !window.douyin_print_list){
        this.print_component_error = true
        return false;
      }
      let arr = []
      for(let i in this.orderInfo.order_sub) {
        if(!this.orderInfo.order_sub[i].checkedType) {
          arr.push(this.orderInfo.order_sub[i].checkedType)
        }
      }
      if(arr.length>0) {
        this.dialog2Show = true
      }else{
        this.dialogExpressConsignerVisible = true
      }
    },
    // 获取网点
    GetWaybillSearch(){
      this.$store.dispatch("batchprint/GetWaybillSearch",{cp_code: this.expressTemplateList[this.activeExpressIndex].company_no });
    },
    CancelAuth(){
      this.$store.dispatch("batchprint/CancelAuth").then(res=>{
        this.GetWaybillSearch() // 取消授权后重新获取网点信息
      })
    },
   
	
  },
};
</script>

<style lang="less" scoped>
.result-container {
  width: 100%;
  height: 100%;
}
.row1 {
  .body-infotable {
    font-size: 13px;
    // margin-left: 10px;
    .table-key {
      display: inline;
      // font-weight: 600;
      // color: #b9b1a7;
      margin-right: 10px;
    }
    /deep/ .el-form-item__content {
      line-height: 0;
    }
    /deep/ .el-select {
      width: 120px;
    }
  }
}
.row2 {
  margin: 5px 0;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #eeeeee;
  .row2-key {
    margin-right: 10px;
    font-weight: 600;
  }
  .row2-item {
    color: #a8a8a8;
    margin-right: 10px;
  }
  .row-item-active {
    color: #f28b38;
    font-size: 13px;
    font-weight: 1000;
  }
}
.row3 {
  .row3-header {
    font-size: 13px;
    // font-weight: 1000;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #efefef;
    display: flex;
    justify-content: space-between;
    
    .shopMsgRow {
      padding: 10px 0px;
    }
    .shopMsgRow-right {
      .shopMsgRow {
      padding: 10px 0px;
      text-align: right;
    }
    }
  }
 
}
/deep/ .iconfont {
  font-size: 12px;
}
/deep/ .el-checkbox__label {
  font-size: 13px;
  color: #b8b8b8;
}

.row5 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  position: relative;
  .row5-col1 {
    width: 400px;
    .col1-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
  .row5-col2 {
    display: flex;
    // flex-direction: column;
    .col2-btn {
      margin: 5px 10px;
      background-image: none;
      color: #fff;
    }
    .btn1 {
      background-color: #23b7e5;
    }
    .btn2 {
      background-color: #02b77e;
    }
  }
  .row5-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 20px;
    color: #fff;
    background-image: -webkit-linear-gradient(bottom, #055ae3, #057ce9);
  }
}
.row6 {
  margin: 20px 0;
}
.row1-header{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contentClass {
  height: 170px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #00bb9c;
    font-size: 50px;
    line-height: 50px;
  }
  span {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-left: 10px;
  }
}
.contentClass2 {
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #ff7614;
  font-size: 22px;
  div:nth-child(1) {
    display: flex;
    align-items: center;
    height: 117px;
  }
  i {
    color: #ff7614;
    font-size: 40px;
    // line-height: 110px;
    margin-left: 25px;
  }
  .contentFooter {
    height: 53px;
    width: 100%;
    background-color: #f3f3f3;
    border-top: 1px solid #eaeaea;
    text-align: right;
    p {
      font-size: 15px;
      line-height: 15px;
      background-color: #ff7614;
      color: #fff;
      display: inline-block;
      padding: 9px 13px;
      border-radius: 4px;
      margin: 10px;
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog {
    background-color: #fff;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 600;
      color: #2c2b3b;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  .circularClass {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 2px solid #fb0404;
    margin-right: 5px;
    cursor: pointer;
  }
  .el-icon-edit {
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
  }
  /deep/ .table-value .el-input__inner {
    border: none;
    color: #ff0000;
    font-weight: 600;
    padding: 0px;
    border-bottom: 1px solid #8dacc3;
        
  }
  /deep/ .table-value .el-input {
    width: 800px;
  }
  
.el-button--text {
    font-size: 13px;
    color: #adadad;
    font-weight: 600;
    padding: 0;
    text-decoration:underline
    
}
.editClass {
  color: #3366cc;
  margin-left: 10px;
}
.editNameClass {
  width: 400px;
  height: 35px;
  background-color: #f4f4f4;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  span {
    color: #f7881d;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
}
/deep/ .editNameClass .el-input input {
  border: 1px solid #c8c8c8;
}
.editBtn {
  font-size: 12px;
  margin-left: 10px;
}
.express-tab-btn-box{
  position: absolute;
  top: -40px;
  height: 40px;
  left: 510px;
  display: flex;
  background-color: #333;
  border-radius: 5px 5px 0 0;
}
.btn-container{
  margin: auto 10px;
  position: relative;
}
.btn-container:not(:first-child)::before{
  content: "|";
  position: absolute;
  left: -10px;
}
.express-pro-box{
  display: flex;
}
.modifyExpressTemplateSequenceBox{
  position: absolute;
  top: -40px;
  background-color: #4f4f4f;
  z-index: 3;
  width: 848px;
}
.ExpressTemplateSaveSequenceBox{
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  width: 130px;
}
.ExpressTemplateSaveSequenceBtn{
  width: 80px;
  margin: 5px 0px;
}
.ExpressTemplateModifySequenceBox{
  font-size: 12px;
  padding: 8px 5px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  width: 714px;
  min-height: 100px;
  background-color: #333;
  color: #ebb563;
}
.ExpressTemplateModifySequenceLabel{
  width: 100px;
  height: 32px;
  line-height: 32px;
  margin: 5px 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.ExpressTemplateModifySequenceLabelLeft{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 5px 0px 0px 5px;
  left: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelRight{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 0px 5px 5px 0px;
  right: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelText{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  padding: 0px 20px;
}
.ExpressTemplateModifySequenceLabelDelete{
  position: absolute;
  right: 0px;
  color: #999999;
  font-weight: bold;
  width: 20px;
  font-size: 12px;
  top: 1px;
}
.ExpressGlobalSettingsBox{
  display: flex;
  height: 100%;
}
.ExpressGlobalSettingsBoxLeft{
  height: 475px;
  width: 390px;
  border-right: 1px solid #6b6e76;
}
.ExpressGlobalSettingsLabel{
  margin-bottom: 14px;
  padding-left: 42px;
}
.ExpressGlobalSettingsItemName{
  display: inline-block;
  margin-right: 20px;
}
.FahuoGlobalSettingsItemName{
  display: inline-block;
  width: 70px;
  text-align: end;
  margin-right: 20px;
  color: #999999;
}
.ExpressGlobalSettingsItemAllSetKDList{
  height: 120px;
  overflow-y: auto;
  padding-left: 4px;
  width: 250px;
  display: inline-block;
  vertical-align: top;
}
.ExpressGlobalSettingsItemAllSetKDListItem{
  background: #eee;
  border-radius: 2px;
  color: #425b6d;
  float: left;
  line-height: 20px;
  margin: 0 10px 10px 0;
  min-height: 20px;
  padding: 0 14px 0 10px;
  position: relative;
}
.ExpressGlobalSettingsItemAllSetKDListItemClose{
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 2px;
}
.ExpressGlobalSettingsItemAllSetKDListItemAddExpress{
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 4px 0 0 112px;
  padding-left: 18px;
  text-decoration: none;
  width: 80px;
  border-radius: 20px;
  background-color: #f39f27;
}
.ExpressGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #fff;
}
.FahuoGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #999999;
}
.ExpressGlobalSettingsBoxRight{
  padding-left: 28px;
  width: 390px;
}
.ExpressGlobalSettingsZoomBox{
  display:flex;
  align-items: center;
  padding-left: 42px;
}
.ExpressAttrBox{
  flex: 1;
  padding: 30px 20px 0;
  background: #4e5159;
}
.ExpressAttrTitle{
  color: #eb9e26;
  font-size: 12px;
  line-height: 32.94px;
  margin: 10px 0px;
}
.ExpressAttrShopName{
  border-bottom: 1px solid #6e7178;
  padding: 0 0 8px;
  color: #fff;
  margin: 20px 0 4px;
}
.ExpressAttrShopOpenServiceTips{
  margin-top: 10px;
  color: #adadad;
  font-size: 12px;
}
.ExpressAttrShopOpenServiceLink{
  margin: 10px 0px;
  color: #b9cede;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.FahuoGlobalSettingsItemInput{
  width: 200px;
  padding: 2px 5px;
  outline:none;
  font-size: 12px;
}
.FahuoGlobalSettingsItemTextarea{
  padding: 2px 5px;
  font-size: 12px;
  outline:none;
  width: 202px;
  height: 48px;
  resize: none;
  vertical-align: top;
}
.RefreshAuthorization{
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  background: #f6891e;
  margin-top: 10px;
}
.noDataClass {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  div {
    display: flex;
    align-items: center;
    p:nth-child(1) {
      width: 5px;
      height: 5px;
      background-color: #f5821f;
      border-radius: 50%;
      margin-right: 10px;
    }
    p:nth-child(2) {
      color: #333;
      font-size: 16px;
      line-height: 16px;
    }
  }
  div:nth-child(2) {
    padding-top: 15px;
  }
}
.DeleteShop{
  color: #bdcfde;
  font-size: 14;
  text-decoration: underline;
  cursor: pointer;
}
.WaybillSiteAddress{
  margin-top: 10px;
  color: #9c9a9c;
}
.WaybillSiteNumber{
  margin-top: 10px;
  color: #adaead;
}
.WaybillSiteInfoBox{
  margin-top: 10px;
  background: #394142;
  border: #313839;
  border-radius: 5px;
  padding: 10px;
}
.WaybillSiteInfoItem{
  display: flex;
}
.WaybillSiteInfoItemRight{
  width: 190px;
  line-height: 1.5;
}
.WaybillSiteInfoItemText{
  word-break:break-all;
  white-space:pre-wrap;
  color: #9c9a9c;
}
.WaybillSiteInfoItemTips{
  color: #00ba9c;
}
.preview-express-box{
  width:800px;height:560px; overflow: auto;padding: 20px; box-sizing: border-box;
}
.preview-express-box img{
  border: 1px solid #eee;
}
</style>